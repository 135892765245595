var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"server-items-length":_vm.maxRecords,"options":_vm.options,"headers":_vm.headers,"items":_vm.franchisesData,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fantasy_name",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(item.fantasy_name))])])],1)],1)],1)]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm._f("cnpj")(item.cnpj))+" ")])],1)]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('a',{on:{"click":function($event){return _vm.toggleContactsDialog(item)}}},[_vm._v(" "+_vm._s(_vm.getLabelContacts(item.contacts))+" ")])])],1)]}},{key:"item.total_franchisees",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.total_franchisees ? item.total_franchisees : 0)+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.getStatusLabel(_vm.isActiveLabel(item.active)).color}},[_vm._v(" fiber_manual_record ")]),_vm._v(" "+_vm._s(_vm.getStatusLabel(_vm.isActiveLabel(item.active)).text)+" ")],1)],1)]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goToEditFranchise(item)}}},[_c('v-list-item-title',[_vm._v("Ver dados da rede ")])],1),(item.active)?_c('v-list-item',{on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-list-item-title',[_vm._v("Cadastrar Churn")])],1):_c('v-list-item',{on:{"click":function($event){return _vm.unsetFranchiseChurn(item)}}},[_c('v-list-item-title',[_vm._v("Remover Churn")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogHistory(item)}}},[_c('v-list-item-title',[_vm._v("Histórico de Churn")])],1)],1)],1)]}}],null,true)}),_c('dialog-history-churn',{attrs:{"type":"franchises","retailer-name":_vm.name,"list-churn":_vm.listChurn,"retailer-cnpj":_vm.cnpj,"client-id":_vm.id},model:{value:(_vm.historyChurn),callback:function ($$v) {_vm.historyChurn=$$v},expression:"historyChurn"}}),_c('dialog-churn',{attrs:{"client_id":_vm.id,"retailer-name":_vm.name,"retailer-cnpj":_vm.cnpj,"value":_vm.dialogSetChurn},on:{"click":_vm.setChurn},model:{value:(_vm.dialogSetChurn),callback:function ($$v) {_vm.dialogSetChurn=$$v},expression:"dialogSetChurn"}}),_c('contacts-details-dialog',{attrs:{"item-selected":_vm.itemSelected},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }