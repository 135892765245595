export const getStatusLabel = status => {
  switch (status?.toUpperCase()) {
    case 'ACTIVE':
      return {
        color: '#07DD18',
        text: 'Ativo'
      }
    default:
      return {
        color: '#FF5252',
        text: 'Inativo'
      }
  }
}
